<template>
  <div v-if="updating">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4 d-flex justify-content-center">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="linkIsInvalid || pickupIsInitiated || deliveryServiceUnavailable">
    <div class="container">
      <div>
        <div class="row animated-container">
          <div class="col-12 col-md-6 mt-md-5">
            <img src="@/assets/images/whoopsie-image-grey.png" class="w-100">
          </div>
          <div class="col-12 col-md-6 mt-md-5 align-self-center">
            <h1 class="mt-4">{{ $t('whoops') }}</h1>
            <ul class="dash mt-3">
              <li v-if="linkIsInvalid">Link is invalid</li>
              <li v-if="pickupIsInitiated">Pickup already initiated with confirmation id {{ confirmationId }}</li>
              <li v-if="deliveryServiceUnavailable">There was an error making the pickup request</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="pickupSuccess" class="container pt-4 pb-4">
      <div>
        <div class="row animated-container">
          <div class="col-12 col-md-6">
            <img src="@/assets/images/order-confirmed.png" class="w-100">
          </div>
          <div class="col-12 col-md-6 align-self-center">
            <h1>Order {{ orderId }}</h1>
            <div>Delivery has been initiated with confirmation id {{ confirmationId }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row animated-container">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-md-9 mt-md-5 mb-3">
              <h1 class="mt-4">Book pickup for order {{ orderId }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="font-weight-bold mt-4">Order information
              </div>
              <div>{{ data.order.store.name }}</div>
              <div>{{ data.order.user_name }}</div>
              <div>{{ data.order.order_id }}</div>
            </div>
            <div class="col-md-3">
              <div class="font-weight-bold mt-4">Pickup user selected
              </div>
              <div>{{ carrierName }}</div>
              <div>{{ carrierService }}</div>
            </div>
            <div class="col-md-3">
              <div class="font-weight-bold mt-4">Pickup address
              </div>
              <div>{{ data.store_supplier.name }}</div>
              <div>{{ data.store_supplier.address }}</div>
              <div>{{ data.store_supplier.zip_code }} {{ data.store_supplier.city }}, {{
                  data.store_supplier.country
                }}
              </div>
            </div>
            <div class="col-md-3">
              <div class="font-weight-bold mt-4">Delivery address
              </div>
              <div>{{ data.order.recipient_name }}</div>
              <div>{{ data.order.address_street }}</div>
              <div>{{ data.order.address_zip }} {{ data.order.address_city }}, {{ data.order.address_country }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-12 col-md-9 mt-md-5 mb-3">
                  <h2 class="mt-4">Confirm parcel dimensions</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12">
                <div v-for="(item,index) in extraParcels" :key="index">
                  <div class="row">
                    <div class="col-md-3 col-sm-6 mb-3 pr-md-1">
                      <div class="font-weight-bold">Height (cm)</div>
                      <input type="text" class="inputfield" v-model="item.height" v-on:blur="setBookingStatus">
                      <div v-if="$v.extraParcels.$each[index].height.$error">
                        <div class="form-field-error" v-if="!$v.extraParcels.$each[index].height.decimal">Enter a valid
                          number
                        </div>
                        <div class="form-field-error" v-else-if="!$v.extraParcels.$each[index].height.minValue">Minimum
                          value is 1
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-3 pr-md-4 pl-md-0">
                      <div class="font-weight-bold">Length (cm)</div>
                      <input type="text" class="inputfield" v-model="item.length" v-on:blur="setBookingStatus">
                      <div v-if="$v.extraParcels.$each[index].length.$error">
                        <div class="form-field-error" v-if="!$v.extraParcels.$each[index].length.decimal">Enter a valid
                          number
                        </div>
                        <div class="form-field-error" v-else-if="!$v.extraParcels.$each[index].length.minValue">Minimum
                          value is 1
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-3 pr-md-1">
                      <div class="font-weight-bold">Width (cm)</div>
                      <input type="text" class="inputfield" v-model="item.width" v-on:blur="setBookingStatus">
                      <div v-if="$v.extraParcels.$each[index].width.$error">
                        <div class="form-field-error" v-if="!$v.extraParcels.$each[index].width.decimal">Enter a valid
                          number
                        </div>
                        <div class="form-field-error" v-else-if="!$v.extraParcels.$each[index].width.minValue">Minimum
                          value is 1
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 mb-3 pr-md-1 pl-md-0">
                      <div class="font-weight-bold">Weight (kg)</div>
                      <div class="row">
                        <div class="col-md-9 pr-md-0">
                          <input type="text" class="inputfield" v-model="item.weight" v-on:blur="setBookingStatus">
                          <div v-if="$v.extraParcels.$each[index].weight.$error">
                            <div class="form-field-error" v-if="!$v.extraParcels.$each[index].weight.decimal">Enter a
                              valid
                              number
                            </div>
                            <div class="form-field-error"
                                 v-else-if="!$v.extraParcels.$each[index].weight.positiveValue">
                              Enter value above 0
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 pl-md-0 d-flex align-items-center">
                          <div class="pointer">
                            <span class="remove-x align-top" v-on:click="removeParcel(index)">X</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-12 col-md-9 mt-md-5 mb-3">
                  <h2 class="mt-4">Pickup time</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="font-weight-bold">Choose pickup date* <img id="pickup-tooltip"
                                                                         src="@/assets/images/tooltip.svg"
                                                                         title="Tooltip"/>
                    <b-tooltip target="pickup-tooltip" triggers="hover">
                      A date when the carrier should pickup the package.
                    </b-tooltip>
                  </div>
                  <div><input type="date" class="inputfield" v-model="pickupDate" :min="today" :max="endDateMax"/></div>
                  <div v-if="$v.pickupDate.$error">
                    <div class="form-field-error" v-if="!$v.pickupDate.required">Can't be empty</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="font-weight-bold">Choose pickup time* <img id="pickup-time-tooltip"
                                                                         src="@/assets/images/tooltip.svg"
                                                                         title="Tooltip"/>
                    <b-tooltip target="pickup-time-tooltip" triggers="hover">
                      A time when the carrier should pickup the package.
                    </b-tooltip>
                  </div>
                  <div><input type="time" class="inputfield" v-model="pickupTime" :min="today" :max="endDateMax"/></div>
                  <div v-if="$v.pickupTime.$error">
                    <div class="form-field-error" v-if="!$v.pickupTime.required">Can't be empty</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="addParcel()">Add parcel</button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4">
              <hr/>
            </div>
          </div>
          <div class="row mb-5">
            <div v-if="!this.editPickup" class="col-md-3">
              <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="bookPickup()">Book pickup</button>
            </div>
            <div v-else class="col-md-3">
              <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="updateParcels()">Update parcels</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="openLabel()">Print label</button>
            </div>
            <div v-if="data.proforma_url" class="col-md-3">
              <button class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="openProforma()">Print proforma</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {decimal, minValue, requiredIf} from "vuelidate/lib/validators";

export default {
  name: 'StoreSupplier',
  data() {
    return {
      linkIsInvalid: false,
      pickupIsInitiated: false,
      deliveryServiceUnavailable: false,
      pickupSuccess: false,
      updating: false,
      pickupDate: null,
      pickupTime: null,
      carrierName: '',
      carrierService: '',
      data: null,
      orderId: '',
      confirmationId: '',
      editPickup: false,
      extraParcels: [],
      extraParcels_temp: []
    }
  },
  validations: {
    pickupDate: {
      required: requiredIf(function () {
        return !this.editPickup;
      })
    },
    pickupTime: {
      required: requiredIf(function () {
        return !this.editPickup;
      })
    },
    extraParcels: {
      $each: {
        weight: {
          decimal,
          positiveValue: value => value > 0.0
        },
        width: {
          decimal,
          minValue: minValue(1)
        },
        length: {
          decimal,
          minValue: minValue(1)
        },
        height: {
          decimal,
          minValue: minValue(1)
        },
      }
    }
  },
  created: function () {
    this.OrderCarrierPickupWithToken()
  },
  computed: {
    today() {
      const today = new Date().toISOString().split('T')[0];
      return today
    },
    endDateMax() {
      const today = new Date()
      let end_time = today.setDate(today.getDate() + 365);
      let end_date = new Date(end_time)
      return end_date.toISOString().split('T')[0];
    }
  },
  methods: {
    openLabel() {
      const encodedData = encodeURIComponent(JSON.stringify(this.extraParcels));
      window.open(`/view-labels?data=${encodedData}`, '_blank');
    },
    openProforma() {
      const encodedData = encodeURIComponent(JSON.stringify(this.data.proforma_url));
      window.open(`/view-proforma?data=${encodedData}`, '_blank');
    },
    OrderCarrierPickupWithToken() {
      const vm = this
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/ordercarriers/pickup/' + this.$route.params.token
      this.updating = true
      this.$http({
        method: 'GET',
        url: api_url,
      })
          .then(function (response) {
            vm.data = response.data
            vm.carrierName = response.data.carrier_name
            vm.carrierService = response.data.carrier_service
            vm.orderId = response.data.order.order_id
            vm.extraParcels = [...response.data.parcels]
            vm.extraParcels_temp = JSON.stringify([...response.data.parcels])
            vm.updating = false
          })
          .catch(function (error,) {
            switch (error.response.status) {
              case 400:
                vm.pickupIsInitiated = true
                vm.confirmationId = error.response.data.confirmation_id
                break
              case 404:
                vm.linkIsInvalid = true
                break
            }
            vm.updating = false
          })
    },
    initiateOrderCarrierPickupWithToken(pickup_time, parcels) {
      const data = {'pickup_time': pickup_time / 1000, parcels: parcels}
      const vm = this
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/ordercarriers/pickup/' + this.$route.params.token
      this.updating = true
      this.$http({
        method: 'POST',
        url: api_url,
        data: data
      })
          .then(function (response) {
            vm.data = response.data
            vm.confirmationId = response.data.confirmation_id
            vm.pickupSuccess = true
            vm.updating = false
          })
          .catch(function (error) {
            switch (error.response.status) {
              case 400:
                vm.pickupIsInitiated = true
                break
              case 404:
                vm.linkIsInvalid = true
                break
              case 409:
                vm.$toastr.e(error.response.data.message)
                break
              case 503:
                vm.deliveryServiceUnavailable = true
                break
            }
            vm.updating = false
          })
    },
    addParcel() {
      this.editPickup = true
      const parcel = {'height': 0.0, 'length': 0.0, 'width': 0.0, 'weight': 0.0}
      this.extraParcels.push(parcel)
    },
    removeParcel(index) {
      if (index >= 0 && index < this.extraParcels.length) {
        this.extraParcels.splice(index, 1);
        this.editPickup = true
      }
    },
    setBookingStatus() {
      if (JSON.stringify(this.extraParcels) !== this.extraParcels_temp) {
        this.editPickup = true
      } else {
        this.editPickup = false
      }
    },
    updateParcels() {
      const vm = this
      if(this.extraParcels.length === 0) {
        this.$toastr.e(
            "There should be atleast one parcel."
        )
        return;
      }
      this.updating = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.updating = false;
        this.$toastr.e(
            "Please check all fields."
        )
        return;
      }
      const headers = {}
      const data = {parcels: this.extraParcels}

      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/ordercarriers/edit/" + this.$route.params.token,
        method: 'POST',
        data: data,
        headers: headers
      })
          .then(response => {
            const shipping_info = response.data.shipping_info
            if (shipping_info !== {}) {
              if(response.data.shipping_info.proforma_url) {
                vm.data.proforma_url = response.data.shipping_info.proforma_url
              }
              this.extraParcels = [...response.data.parcels]
              this.extraParcels_temp = JSON.stringify([...response.data.parcels])
              this.editPickup = false
              this.updating = false;
              vm.$toastr.s(
                  "Parcels and label updated successfully."
              );
            } else {
              vm.$toastr.e(
                  "There was an error in parcels."
              );
            }
          })
          .catch(error => {
            this.updating = false;
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
          })
    },
    bookPickup() {
      this.updating = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.updating = false;
        this.$toastr.e(
            "Please check all fields."
        )
        return;
      }
      this.updating = false;
      const combinedString = `${this.pickupDate}T${this.pickupTime}:00`
      const pickupEpochTime = Date.parse(combinedString)
      const currentEpochTime = Date.now();
      if (pickupEpochTime <= currentEpochTime) {
        this.$toastr.e('The pickup time cannot be in the past.');
        return;
      }
      if (
          (this.weight || this.width || this.height || this.length) &&
          (!this.weight || !this.width || !this.height || !this.length)
      ) {
        this.$toastr.e('Please fill all the dimensions or leave all empty.');
        return;
      }
      this.initiateOrderCarrierPickupWithToken(pickupEpochTime, this.extraParcels)
    }
  }
}
</script>
